#home {
  height: 100vh;
}

#landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  /*padding: 0px 200px;*/
}

#landing-page .container {
  display: grid;
  grid-template-areas:
    "left rt"
    "left rb"
    "bottom bottom";
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 0px;
}

#landing-page .memoji {
  height: 150px;
}

#landing-page h1 {
  font-weight: 700px;
}

#landing-page p {
  padding: 1px;
}

/*Content*/

#landing-page .top-left {
  grid-area: left;
  align-self: center;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding: 0px 50px;
}

.top-rt {
  grid-area: rt;
  font-size: 100px;
  display: flex;
  align-items: flex-end;
}
.top-rb {
  grid-area: rb;
  display: flex;
  align-items: flex-end;
}

.bottom {
  padding-top: 20px;
  grid-area: bottom;
}

/* Mobile */
@media (max-width: 768px) {
  #landing-page {
    display: block;
    height: 100%;
    width: 100%;
  }
  #landing-page .memoji {
    height: 100px;
  }
  #landing-page .container {
    display: block;
    padding-top: 50px;
  }
  #landing-page .top-left {
    display: none;
  }

  .top-rt {
    padding-top: 20px;
    font-size: 100px;
    padding-bottom: 50px;
  }
  .top-rb {
  }

  .bottom {
    padding-top: 20px;
    width: 100%;
  }
}
