@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.5rem;
  font-family: "San Francisco", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 500;
  padding: 5px;
  background-color: #f5f5f7;
  color: #000;
  line-height: 1.5em;
}

#home {
  height: 100vh;
}

#landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  /*padding: 0px 200px;*/
}

#landing-page .container {
  display: grid;
  grid-template-areas:
    "left rt"
    "left rb"
    "bottom bottom";
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 0px;
}

#landing-page .memoji {
  height: 150px;
}

#landing-page h1 {
  font-weight: 700px;
}

#landing-page p {
  padding: 1px;
}

/*Content*/

#landing-page .top-left {
  grid-area: left;
  align-self: center;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding: 0px 50px;
}

.top-rt {
  grid-area: rt;
  font-size: 100px;
  display: flex;
  align-items: flex-end;
}
.top-rb {
  grid-area: rb;
  display: flex;
  align-items: flex-end;
}

.bottom {
  padding-top: 20px;
  grid-area: bottom;
}

/* Mobile */
@media (max-width: 768px) {
  #landing-page {
    display: block;
    height: 100%;
    width: 100%;
  }
  #landing-page .memoji {
    height: 100px;
  }
  #landing-page .container {
    display: block;
    padding-top: 50px;
  }
  #landing-page .top-left {
    display: none;
  }

  .top-rt {
    padding-top: 20px;
    font-size: 100px;
    padding-bottom: 50px;
  }
  .top-rb {
  }

  .bottom {
    padding-top: 20px;
    width: 100%;
  }
}

.text-primary {
  color: #000;
}

.text-grey {
  color: #a1a1a6;
}

.projects {
  padding: 50px 200px;
}

.body {
  display: flex;
  justify-content: center;
}

.list {
  display: grid;
  grid-template-areas:
    "one two"
    "three four";
  grid-gap: 50px;
  text-align: center;
  padding: 30px 200px;
}

.project-1 {
  grid-area: one;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 10px;
  padding-bottom: 20px;
}
.project-2 {
  grid-area: two;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 10px;
  padding-bottom: 20px;
}
.project-3 {
  grid-area: three;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 10px;
  padding-bottom: 20px;
}
.project-4 {
  grid-area: four;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 10px;
  padding-bottom: 20px;
}

.project-title {
  padding-bottom: 10px;
}

.technologies {
  padding-top: 10px;
  font-size: 1.3 rem;
  display: grid;
  grid-template-areas: "tl tr" "bl br";
}

.fa {
  position: absolute;
  top: 0;
  left: 0;
}

.lang-1 {
  grid-area: tl;
}
.lang-2 {
  grid-area: tr;
}
.lang-3 {
  grid-area: bl;
}
.lang-4 {
  grid-area: br;
}

.fa {
  display: inline;
}

.technologies .icons {
  display: inline-block;
  height: 1.4rem;
  padding-right: 5px;
}

/* Mobile */
@media (max-width: 768px) {
  .projects {
    padding: 20px 20px;
  }
  .header {
    padding-left: 50px;
  }
  .list {
    display: grid;
    grid-template-areas:
      "one"
      "two"
      "three"
      "four";
    grid-gap: 50px;
    text-align: center;
    padding: 30px 110px;
  }
}

@media (max-width: 1200px) {
  .projects {
    padding: 20px 20px;
  }
  .header {
    padding-left: 50px;
  }
  .list {
    padding: 30px 150px;
  }
}

@media (max-width: 375px) {
  .list {
    padding: 20px 40px;
  }
}

main {
  padding: 50px 200px;
}
#contact-form h1,
#contact-form p {
  text-align: center;
}

#contact-form {
  /*font-size: 18px;*/
  margin: auto;
  max-width: 1500px;
}

#contact-form h1 {
  padding-top: 30px;
  /*font-size: 30px;*/
}

#contact-form label {
  display: block;
  margin-top: 30px;
  margin-bottom: 5px;
}

#contact-form input,
#contact-form textarea {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px #000 solid;
  font-size: 12px;
}

#contact-form textarea {
  height: 200px;
}

#contact-form input:focus,
#contact-form textarea:focus {
  outline: none;
}

.submit {
  display: inline-block;
  color: black;
  background: #f5f5f7;
  padding: 15px 3px;
  cursor: pointer;
  text-align: left;
  font-weight: 600;
  font-size: 17px;
  border: none !important;
}

.submit:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  main {
    padding: 20px 130px;
  }
}

@media (max-width: 375px) {
  main {
    padding: 20px 50px;
  }
}

#navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 25px 50px;
  z-index: 10;
}

#navbar .logo {
  font-size: 3.2rem;
  font-weight: bold;
}

#navbar .menu {
  display: flex;
  justify-content: space-around;
  color: #a1a1a6;
  width: 30vw;
}

NavLink .menu:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

/* Mobile */
@media (max-width: 768px) {
  #navbar {
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }
  #navbar a {
    padding: 10px 10px;
    margin: 0 3px;
  }
}

@media (max-width: 1200px) {
  .navbar a {
    padding: 10px 10px;
    margin: 0 3px;
  }
}

/*

#navbar .right {
  display: flex;
  justify-content: space-between;
  justify-content: space-around;
  color: #a1a1a6;
  width: 80%;
}


#navbar .menu {
  justify-content: flex-end;
}
*


#navbar {
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 25px 50px;
  z-index: 10;
}

#navbar .logo {
  font-size: 3.2rem;
  font-weight: bold;
}

#navbar .menu {
  display: flex;
  justify-content: space-between;
  width: 30vw;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

/*
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  /* Second Nav */
/* margin-right: 24px; */
/* Third Nav */
/* width: 100vw;
  white-space: nowrap; *
  @media screen and (max-width: 768px) {
    display: none;
  }
  */

