#navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 25px 50px;
  z-index: 10;
}

#navbar .logo {
  font-size: 3.2rem;
  font-weight: bold;
}

#navbar .menu {
  display: flex;
  justify-content: space-around;
  color: #a1a1a6;
  width: 30vw;
}

NavLink .menu:hover {
  text-decoration-line: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

/* Mobile */
@media (max-width: 768px) {
  #navbar {
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }
  #navbar a {
    padding: 10px 10px;
    margin: 0 3px;
  }
}

@media (max-width: 1200px) {
  .navbar a {
    padding: 10px 10px;
    margin: 0 3px;
  }
}

/*

#navbar .right {
  display: flex;
  justify-content: space-between;
  justify-content: space-around;
  color: #a1a1a6;
  width: 80%;
}


#navbar .menu {
  justify-content: flex-end;
}
*


#navbar {
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding: 25px 50px;
  z-index: 10;
}

#navbar .logo {
  font-size: 3.2rem;
  font-weight: bold;
}

#navbar .menu {
  display: flex;
  justify-content: space-between;
  width: 30vw;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

/*
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  /* Second Nav */
/* margin-right: 24px; */
/* Third Nav */
/* width: 100vw;
  white-space: nowrap; *
  @media screen and (max-width: 768px) {
    display: none;
  }
  */
