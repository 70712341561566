main {
  padding: 50px 200px;
}
#contact-form h1,
#contact-form p {
  text-align: center;
}

#contact-form {
  /*font-size: 18px;*/
  margin: auto;
  max-width: 1500px;
}

#contact-form h1 {
  padding-top: 30px;
  /*font-size: 30px;*/
}

#contact-form label {
  display: block;
  margin-top: 30px;
  margin-bottom: 5px;
}

#contact-form input,
#contact-form textarea {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px #000 solid;
  font-size: 12px;
}

#contact-form textarea {
  height: 200px;
}

#contact-form input:focus,
#contact-form textarea:focus {
  outline: none;
}

.submit {
  display: inline-block;
  color: black;
  background: #f5f5f7;
  padding: 15px 3px;
  cursor: pointer;
  text-align: left;
  font-weight: 600;
  font-size: 17px;
  border: none !important;
}

.submit:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  main {
    padding: 20px 130px;
  }
}

@media (max-width: 375px) {
  main {
    padding: 20px 50px;
  }
}
