@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.5rem;
  font-family: "San Francisco", "Helvetica Neue", Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: 500;
  padding: 5px;
  background-color: #f5f5f7;
  color: #000;
  line-height: 1.5em;
}
