.projects {
  padding: 50px 200px;
}

.body {
  display: flex;
  justify-content: center;
}

.list {
  display: grid;
  grid-template-areas:
    "one two"
    "three four";
  grid-gap: 50px;
  text-align: center;
  padding: 30px 200px;
}

.project-1 {
  grid-area: one;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 10px;
  padding-bottom: 20px;
}
.project-2 {
  grid-area: two;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 10px;
  padding-bottom: 20px;
}
.project-3 {
  grid-area: three;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 10px;
  padding-bottom: 20px;
}
.project-4 {
  grid-area: four;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 10px;
  padding-bottom: 20px;
}

.project-title {
  padding-bottom: 10px;
}

.technologies {
  padding-top: 10px;
  font-size: 1.3 rem;
  display: grid;
  grid-template-areas: "tl tr" "bl br";
}

.fa {
  position: absolute;
  top: 0;
  left: 0;
}

.lang-1 {
  grid-area: tl;
}
.lang-2 {
  grid-area: tr;
}
.lang-3 {
  grid-area: bl;
}
.lang-4 {
  grid-area: br;
}

.fa {
  display: inline;
}

.technologies .icons {
  display: inline-block;
  height: 1.4rem;
  padding-right: 5px;
}

/* Mobile */
@media (max-width: 768px) {
  .projects {
    padding: 20px 20px;
  }
  .header {
    padding-left: 50px;
  }
  .list {
    display: grid;
    grid-template-areas:
      "one"
      "two"
      "three"
      "four";
    grid-gap: 50px;
    text-align: center;
    padding: 30px 110px;
  }
}

@media (max-width: 1200px) {
  .projects {
    padding: 20px 20px;
  }
  .header {
    padding-left: 50px;
  }
  .list {
    padding: 30px 150px;
  }
}

@media (max-width: 375px) {
  .list {
    padding: 20px 40px;
  }
}
